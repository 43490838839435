import React from "react";

import { ButtonContainer, ButtonText } from "./styles";
import { Pressable, Text, View,  } from "react-native";

interface LongButtonProps {
  backgroundColor?: string;
  text: string;
  textColor?: string;
}


const LongButton = ({backgroundColor, text, textColor}: LongButtonProps ): JSX.Element => {

  return (
    <View style={{
      width: "100%",
      height: 50,
      backgroundColor: backgroundColor || '#fff',
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 50
    }}>
      <Text style={{
        fontSize: 16,
        fontWeight: "bold",
        fontFamily: 'Inter-Regular',
        color: textColor || '#000'
      }}>{text}</Text>
    </View>
  );
};

export default LongButton;
