import React, {useContext, useEffect, useState} from "react";
import { ContainerWrapper } from "./styles/profile";
import { FlatList, Image, ScrollView, StyleSheet, Text, View, SafeAreaView, ActivityIndicator, TextInput, Keyboard, Button, Pressable } from "react-native";
import { ProfileContext } from "../contexts/ProfileContext";
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {TouchableWithoutFeedback} from "react-native-gesture-handler";
import { TransferContext } from "../contexts/TransferContext";
import SafeArea from "../components/SafeArea";


const Transfer = ({navigation}: any): JSX.Element => {
  const transferContext = useContext(TransferContext)
  const profileContext = useContext(ProfileContext)
  const [screen, setScreen] = useState("options");

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const delay = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const transferToCharity = async () => {
    try{
      setScreen("charity-loading")
      await delay(500)
      await transferContext.transferToCharity()
      await profileContext.getRewards()
      setScreen("charity-complete")
    } catch (error: any) { 
      setScreen("error")
      setErrorMessage(error.message || error)
    }
  }

  const validateFields = (name: string,email: string): boolean => {
    if (name.length === 0 || email.length === 0){
      return false
    }
    return true
  }

  const transferToCash = async () => {
    try{
      setScreen("cash-loading")
      await delay(500)
      await transferContext.transferToCash(name,email)
      setScreen("cash-complete")
    } catch (error: any) { 
      setScreen("error")
      setErrorMessage(error.message || error)
    }
  }

  const screenOptions = () => {
    if (screen === "options"){
      return (
        <>
          <View style={{alignItems: "center", justifyContent: "center", marginTop: 20, backgroundColor: '#ededed',  marginLeft: 20, marginRight: 20, borderRadius: 10, paddingTop: 20, paddingBottom: 20}}>
                  <Text style={{fontSize: 20, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Available Balance: {profileContext.rewards && profileContext.rewards.available?.total}</Text>
          </View>
          <View style={{borderBottomColor: "grey", borderBottomWidth: StyleSheet.hairlineWidth, marginTop: 20}}></View>
          <View style={{justifyContent: "center", marginTop: 20}}>
            <Pressable onPress={() => transferToCharity()}>
              <View style={{alignItems:"center", paddingTop: 10,  marginLeft: 20, marginRight: 20, paddingBottom: 10, borderColor: "grey", borderWidth: StyleSheet.hairlineWidth, flexDirection: "row", borderRadius: 10}}>
                  <MaterialCommunityIcons name="hand-heart-outline" color="red" size={26} style={{marginLeft: 10, marginRight: 10}} />
                  <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Transfer balance to charity wallet.</Text>
              </View>
            </Pressable>
            <View style={{flexDirection: "row", justifyContent: "center", marginTop: 20, marginBottom: 20}}>
              <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Bold'}}>Or</Text>
            </View>
            <Pressable onPress={() => setScreen("cashout-form")}>
              <View style={{alignItems:"center", paddingTop: 10,  marginLeft: 20, marginRight: 20, paddingBottom: 10, borderColor: "grey", borderWidth: StyleSheet.hairlineWidth, flexDirection: "row", borderRadius: 10}}>
                  <MaterialCommunityIcons name="bank" color="#3e9c35" size={26} style={{marginLeft: 10, marginRight: 10}} />
                  <Text style={{fontSize: 13, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Transfer balance to cash or gift cards.</Text>
              </View>
            </Pressable>
          </View>
        </>
      )
    } else if (screen === "charity-loading") {
      return (
        <>
          <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
            <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Transferring {profileContext.rewards && profileContext.rewards.available?.total} to charity wallet.</Text>
            <ActivityIndicator style={{marginTop: 20}}/> 
          </View>
        </>
      )
    } else if (screen === "cash-loading") {
      return (
        <>
          <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
            <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Sending rewards email to {email}.</Text>
            <ActivityIndicator style={{marginTop: 20}}/> 
          </View>
        </>
      )
    }
    else if (screen === "charity-complete") {
      return (
        <>
          <View style={{flex: 1, justifyContent: "center", alignItems: "center", marginLeft: 20, marginRight: 20}}>
            <View style={{flexDirection: "row"}}>
              <Text style={{fontSize: 20, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Transfer is Complete.</Text>
              <MaterialCommunityIcons name="checkbox-marked-circle" color="green" size={26} style={{marginLeft: 5}} />
            </View>
              <View style={{width: "100%", marginTop: 20}}>
                <Pressable onPress={()=>navigation.navigate('Profile')}>
                    <View style={{height: 50, borderWidth: 1, borderColor: "#0013FF", borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                      <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Go Back to Profile</Text>
                    </View>
                </Pressable>
              </View>
          </View>
        </>
      )
    } else if (screen === "cash-complete") {
      return (
        <>
          <View style={{flex: 1, justifyContent: "center", alignItems: "center", marginLeft: 20, marginRight: 20}}>
            <View style={{flexDirection: "row"}}>
              <Text style={{fontSize: 20, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Email has been sent.</Text>
              <MaterialCommunityIcons name="checkbox-marked-circle" color="green" size={26} style={{marginLeft: 5}} />
            </View>
              <View style={{width: "100%", marginTop: 20}}>
                <Pressable onPress={()=>navigation.navigate('Profile')}>
                    <View style={{height: 50, borderWidth: 1, borderColor: "#0013FF", borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                      <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Go Back to Profile</Text>
                    </View>
                </Pressable>
              </View>
          </View>
        </>
      )
    } else if (screen === "cashout-form") {
      return (
        <>
          <View style={{flex: 1, alignItems: "center", marginLeft: 20, marginRight: 20}}>
            <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular', marginTop: 20, marginBottom: 20, textAlign: "center"}}>We will send a rewards link to your email - please ensure this is correct.</Text>
            <View style={{width: "100%"}}>
                <View style={{height: 50,  backgroundColor: '#ededed', paddingLeft: 20, borderRadius: 10, flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 20}}>
                    <TextInput
                      style={{color: '#000', flex: 1}}
                      placeholder={"Enter your name"}
                      placeholderTextColor={'#000'}
                      onChangeText={setName}
                      value={name} 
                    />
                </View>
                <View style={{height: 50,  backgroundColor: '#ededed', paddingLeft: 20, borderRadius: 10, flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                    <TextInput
                      style={{color: '#000', flex: 1}}
                      placeholder={"Enter your email"}
                      placeholderTextColor={'#000'}
                      onChangeText={setEmail}
                      keyboardType="email-address"
                      value={email}
                    />
                </View>
            </View>
            <View style={{width: "100%", marginTop: 20}}>
              <Pressable onPress={()=>transferToCash()} disabled={!validateFields(name,email)}>
                  <View style={{height: 50, borderWidth: 1, borderColor: "#0013FF", borderRadius: 15, justifyContent: "center", alignItems: "center", opacity: validateFields(name,email) ? 1 : .3}}>
                    <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Send Email</Text>
                  </View>
              </Pressable>
            </View>
          </View>
        </>
      )
    } else if (screen === "error") {
      return (
        <>
          <View style={{flex: 1, justifyContent: "center", alignItems: "center", marginLeft: 20, marginRight: 20}}>
            <Text style={{fontSize: 20, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular', textAlign: "center"}}>An error occured. Try again at another time</Text>
            {errorMessage &&
              <Text style={{fontSize: 15, color: "red", fontWeight: "bold", fontFamily: 'Inter-Regular', textAlign: "center", marginTop: 20, marginBottom: 20}}>Error: {errorMessage}</Text>
            }
              <View style={{width: "100%", marginTop: 20}}>
                <Pressable onPress={()=>setScreen('options')}>
                    <View style={{height: 50, borderWidth: 1, borderColor: "#0013FF", borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                      <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular', textAlign: "center"}}>Go Back</Text>
                    </View>
                </Pressable>
              </View>
          </View>
        </>
      )
    }
  }
  
  return (
      <SafeArea style={{backgroundColor: "#f8f8f8"}}>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <ContainerWrapper>
            <View style={{flex: 1}}>
              {screenOptions()}
            </View>   
          </ContainerWrapper>
        </TouchableWithoutFeedback>
      </SafeArea>
  );
};

export default Transfer;
