import React from 'react'
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import NearbyStack from './Nearby';
import SearchStack from './Search';
import ProfileStack from './Profile';

const Tab = createMaterialBottomTabNavigator<any>();

const BottomTabs = () => {
  return (
    <Tab.Navigator
      barStyle={{ backgroundColor: '#f8f8f8', borderWidth: 1, borderColor: 'grey'}}
      inactiveColor="#808080"
      activeColor="#0013FF"
      sceneAnimationEnabled={true}
      shifting={false}
    >
      <Tab.Screen
        name="SearchStack"
        component={SearchStack}
        options={{
          title: 'Online',
          tabBarIcon: ({focused}) => (
          <MaterialCommunityIcons name="web" color={focused ? "#0013FF" : "#808080"} size={26} />
        )}}
      />
      <Tab.Screen
        name="NearbyStack"
        component={NearbyStack}
        options={{
          title: 'Local',
          tabBarIcon: ({focused}) => (
          <MaterialCommunityIcons name="map-marker" color={focused ? "#0013FF" : "#808080"} size={26} />
        )}}
      />
      <Tab.Screen
        name="ProfileStack"
        component={ProfileStack}
        options={{
          title: 'Profile',
          tabBarIcon: ({focused}) => (
          <MaterialCommunityIcons name="account" color={focused ? "#0013FF" : "#808080"} size={26} />
        )}}
      />
    </Tab.Navigator>
  );
};

export default BottomTabs;
