import styled from 'styled-components';
import { View, Image, Text } from 'react-native';

export const ContainerWrapper = styled(View)`
  height: 100%;
  width: 100%;
`;

export const Container = styled(View)`
  flex: 1;
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const HeroContainer = styled(View)`
  align-items: center;
`;

export const HeroImage = styled(Image)`
  width: 200px;
  height: 200px;
  resize-mode: cover;
`;

export const TextContent = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-horizontal: 20px;
`;

export const Title = styled(Text)`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: #fff;
`;

export const Description = styled(Text)`
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  color: #fff;
`;

export const ButtonContainer = styled(View)`
  padding-horizontal: 20px;
`;
