import React, {useContext, useEffect, useRef} from "react";
import { Image, Text, View, Pressable, SafeAreaView, Animated } from "react-native";
import InputField from "../components/InputField";
import LongButton from "../components/LongButton";
import { AuthContext } from "../contexts/AuthContext";
import { LinearGradient } from 'expo-linear-gradient';
import { ContainerWrapper } from "./styles/signup";
import cultivateLogoWhite from '../../assets/cultivate-logowhite.png'


const Loading = ({navigation}: any): JSX.Element => {
  const authContext = useContext(AuthContext)
  // Define the animated values
  const START_WIDTH = 40;
  const START_HEIGHT = 10;
  const END_WIDTH = 160;
  const END_HEIGHT = 40;
  const width = useRef(new Animated.Value(START_WIDTH)).current;
  const height = useRef(new Animated.Value(START_HEIGHT)).current;

  useEffect(() => {
    Animated.parallel([
      Animated.timing(width, {
        toValue: END_WIDTH,
        duration: 2000,
        useNativeDriver: false,
      }),
      Animated.timing(height, {
        toValue: END_HEIGHT,
        duration: 2000,
        useNativeDriver: false,
      }),
    ]).start(verifyLogin)
  },[])

  const verifyLogin = async () => {
    if (authContext.isAuthenticated){
      navigation.navigate('App')
      return;
    } else {
      const isLoggedIn = await authContext.checkAndSetCredentials();
      if (isLoggedIn) {
        navigation.navigate('App')
        return;
      }
      navigation.navigate('SignUp')
    }
  }

  return (
      <LinearGradient colors={['#0013FF', '#9E00FF']} style={{height: "100%"}}>
          <View style={{height: "100%",width: "100%"}}>
            <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
              <Animated.Image source={{uri: cultivateLogoWhite}} resizeMode='contain' style={{width: width, height: height}}/>
            </View>
          </View>
      </LinearGradient>
  )
};

export default Loading;
