import React, {useContext, useEffect, useState, useCallback, useRef} from "react";
import LongButton from "../components/LongButton";
import { ButtonContainer, Container, ContainerWrapper } from "./styles/join";
import { Image, ScrollView, Text, View, Dimensions, TextInput, Modal, Pressable , ActivityIndicator, SafeAreaView, StyleSheet, Keyboard} from "react-native";
import { SafeArea } from "./styles/utils";
import { NearbyContext } from "../contexts/NearbyContext";
import ProductRow from "../components/ProductRow";
import { FlashList } from "@shopify/flash-list";
import Slider from '@react-native-community/slider';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import cultivateLogoBlue from '../../assets/cultivate-logobluepurple.png'

const {width, height} = Dimensions.get('window')

const ASPECT_RATIO = width / height
const LATITUDE_DELTA = 0.0922
const LONGITUDE_DELTA = LATITUDE_DELTA * ASPECT_RATIO

const Nearby = ({ navigation }: any): JSX.Element => {
  const [inputValue, setInputValue] = useState('')
  const [fromSearch, setFromSearch] = useState(false)
  const [value, setValue] = useState('')
  const [inputZipCode, setInputZipCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const nearbyContext = useContext(NearbyContext)
  const [displayData, setDisplayData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const[isKeyFocused, setIsKeyFocused] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [distance, setDistance] = useState(15);
  const [filteredData, setFilteredData] = useState<any[]>([])
  const searchInputRef = useRef(null);

  const filters = (result: any[], newDistance: number) => {
    return result.filter((item)=> item.distance && Number((item.distance as string).split(' ')[0]) <= newDistance)
  }

  useEffect(()=>{ 
    if (displayData.length > 0) {
      setFilteredData(filters(displayData, distance))
    }
  },[displayData, distance])

  const searchProduct = async (searchQuery: string, passsedZipcode?: string) => {
    setLoading(true)
    let result = []
    try {
      result = await nearbyContext.getNearbyProducts(searchQuery,passsedZipcode || nearbyContext.currentZipcode)
    } catch (err){
      setLoading(false)
    }
    setDisplayData(result)
    setLoading(false)
  }

  const onSearchSubmit = async () => {
    setIsKeyFocused(false);
    if (nearbyContext.currentZipcode.length === 0) {
      setModalVisible(true)
      setFromSearch(true)
    } else {
      if (inputValue.length > 0){
        setValue(inputValue)
        setModalVisible(false)
        await searchProduct(inputValue);
      }
    }
  }

  const onCloseModal = () => {
    setModalVisible(false)
    setFromSearch(false);
    setInputZipCode('')
    setErrorMessage('')
  }

  const onFinishModal = async (passedZipCode: string) => {
    const zipRegex = /^[0-9]{5}$/
    if(!zipRegex.test(passedZipCode.trim())){
      setErrorMessage('Invalid Zip Code Format')
    } else {
      setModalVisible(false)
      nearbyContext.setZipCode(passedZipCode)
      setInputZipCode('')
      setErrorMessage('')
      if (fromSearch){
        if (inputValue.length > 0){
          setValue(inputValue)
          setModalVisible(false)
          await searchProduct(inputValue, passedZipCode);
        }
      }
    }
  }

  const renderItem = useCallback(({ item }: any) => ( <ProductRow name={item.name || "Unknown"} price={item.price} image={item.image} merchantName={item.merchant?.name || item.seller} merchantId={item.merchantId} distance={item.distance} savingsAmount={item.donationAmount} rating={item.productRating} reviewCount={item.productReviewCount} url={item.url}/>), [])

  return (
    <>
        <View style={
          {height: "100%",
          width: "100%",
          }}>
            <View style={{justifyContent: "center", width: "100%", alignItems: "center"}}>
              <Image source={{uri: cultivateLogoBlue}} resizeMode='contain' style={{width: 120, height: 30}}></Image>
            </View>
            <View style={{flex: 3, marginTop: 10}}>
              <View style={{flex: 1}}>
                  <View style={{flexDirection: 'row', alignItems: "center", marginBottom: 5, justifyContent: "flex-end", marginRight: 10}}>
                    <Pressable onPress={()=>setModalVisible(true)}>
                      <View style={{backgroundColor: "#fff", shadowColor: '#000', borderRadius: 5, shadowOffset: { width: 0, height: 0 }, shadowOpacity: 0.8, shadowRadius: 1, justifyContent: "center", alignItems: "center", padding: 5,marginBottom: 10, marginLeft: 10}}>
                        <Text style={{color: '#0013FF', fontWeight: 'bold', fontFamily: 'Inter-Regular'}}>
                        { !!nearbyContext.currentZipcode ?
                          `Zip: ${nearbyContext.currentZipcode}`
                          :
                          "Add Zip Code"
                        }
                        </Text>
                    </View>
                    </Pressable>
                  </View>
                  <View style={{marginLeft: 10, marginRight: 10, borderBottomWidth: StyleSheet.hairlineWidth, paddingBottom: 10, borderBottomColor: "grey"}}>
                    <View style={{width: '100%', marginBottom: 5, flexDirection: "row"}}>
                        <Pressable onPress={()=>{searchInputRef.current && (searchInputRef.current as any).focus()}} style={{height: 50, flex: 5,  backgroundColor: '#ededed', paddingLeft: 20, borderRadius: 10, flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                            <TextInput
                              ref={searchInputRef}
                              style={{color: '#000', flex: 1}}
                              placeholder={"Search local products"}
                              placeholderTextColor={'#000'}
                              onChangeText={setInputValue}
                              value={inputValue}
                              onFocus={()=>setIsKeyFocused(true)}
                              returnKeyType='search'
                              onSubmitEditing={onSearchSubmit}
                            />
                             {isKeyFocused &&
                              <Pressable style={{marginRight: 10}} onPress={()=>setInputValue("")}>
                                <MaterialCommunityIcons name="close-circle-outline" color="grey" size={26} />
                              </Pressable>
                              }
                        </Pressable>
                        {isKeyFocused &&
                          <View style={{flexDirection: "row", alignItems: "center", backgroundColor: "#f8f8f8", height: "100%", flex: 1, marginLeft: 5}}>
                            <Pressable onPress={()=>{Keyboard.dismiss(); setIsKeyFocused(false); setInputValue("");}}>
                                <Text style={{fontSize: 16, color: "#000"}}>
                                  Cancel
                                </Text>
                            </Pressable>
                          </View>
                        }
                    </View>
                    <View style={{marginTop: 10}}>
                      <Text style={{fontFamily: 'Inter-Regular'}}>Distance: {"< " + distance} {distance > 1 ? "miles" : "mile"}</Text>
                      <Slider
                        style={{width: "100%", height: 40}}
                        step={1}
                        minimumValue={1}
                        maximumValue={15}
                        minimumTrackTintColor="#0013FF"
                        maximumTrackTintColor="grey"
                        onValueChange={(value)=>setDistance(value)}
                        value={distance}
                        thumbTintColor="#fff"
                      />
                     </View>
                  </View>
                  <View style={{marginLeft: 10, marginRight: 10, flex: 1}}>
                    { !loading ? (
                      <>
                        { filteredData.length > 0 ?
                          <FlashList
                            estimatedItemSize={50}
                            data={filteredData}
                            renderItem={renderItem}
                            showsVerticalScrollIndicator={true}
                          />
                          :
                          <>
                            {
                              displayData.length > 0 ? 
                              <View style={{flex: 1, alignItems: "center", justifyContent: "center"}}>
                                <Text style={{color: "#000", fontSize: 15, fontWeight: "bold", fontFamily: 'Inter-Regular'}}>
                                  Move the slider to see more results
                                </Text>
                            </View>
                            : <>
                              {value.length > 0 ?
                                <View style={{flex: 1, alignItems: "center", justifyContent: "center"}}>
                                  <Text style={{color: "#000", fontSize: 15, fontWeight: "bold", fontFamily: 'Inter-Regular'}}>
                                    No Results :(
                                  </Text>
                                </View>
                                :
                                <View style={{flex: 1, alignItems: "center", justifyContent: "center", marginLeft: 20, marginRight: 20}}>
                                  <Text style={{color: "grey", fontSize: 15, textAlign: "center", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>
                                    Cultivate helps you find local products, use the search bar above to get started.
                                  </Text>
                                </View>
                                }
                            </>
                            }
                          </>
                        }
                      </>
                    )
                    : 
                    <View style={{flex: 1, alignItems: "center",  marginTop: 20}}>
                      <ActivityIndicator/>
                    </View>
                  }
                </View>
              </View>
            </View>
        </View>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            onCloseModal()
          }}>
          <View style={{flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 22,
            }}>
            <View style={{
              margin: 20,
              backgroundColor: 'white',
              alignItems: 'center',
              justifyContent: 'space-around',
              borderRadius: 20,
              padding: 10,
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              width: 300,
              height: 150,
              shadowOpacity: 0.25,
              shadowRadius: 4,
              elevation: 5,
            }}>
               <View style={{position: "absolute", top: 10, left: 10}}>
                  <Pressable onPress={()=>onCloseModal()}>
                    <Text style={{fontWeight: "bold", fontSize: 18, fontFamily: 'Inter-Regular'}}>X</Text>
                  </Pressable>
                </View>
              <View>
                <Text style={{fontWeight: "bold", fontSize: 15, fontFamily: 'Inter-Regular'}}>Enter Zip Code</Text>
              </View>
              {
                errorMessage.length > 0 &&
                <Text style={{color: "red", fontSize: 12, fontWeight: "bold", fontFamily: 'Inter-Regular'}}>{errorMessage}</Text>
              }
              <View style={{width: "90%"}}>
                <ScrollView>
                  <TextInput
                    style={{ height: 40, width: '100%', backgroundColor: '#ededed', paddingLeft: 20, color: '#000', borderRadius: 20}}
                    placeholder={"(e.g. 37203)"}
                    placeholderTextColor={'#000'}
                    onChangeText={setInputZipCode}
                    value={inputZipCode}
                    keyboardType='numeric'
                    returnKeyType='done'
                    maxLength={5}
                    onSubmitEditing={onSearchSubmit}
                  />
                </ScrollView>
              </View>
              <View>
                <Pressable onPress={()=>onFinishModal(inputZipCode)}>
                  <Text style={{fontWeight: "bold", fontSize: 15, color: "#0013FF", fontFamily: 'Inter-Regular'}}>Finish</Text>
                </Pressable>
              </View>
            </View>
          </View>
        </Modal>
        </>
  );
};

export default Nearby;
