import React, { createContext, useState, useContext, useEffect } from 'react';
import { doRequest, useCultivateBaseApi, useCultivateMobileApi } from '../api/ApiClient';
import { AuthContext } from './AuthContext';
import WebViewComponent from '../components/WebView';
import { Platform } from 'react-native';

export interface SearchContextType {
  openUrl: any
}
// Initial State
const initialState = {
  openUrl: () => null,
};

// Create Context
export const WebViewContext = createContext<SearchContextType>(initialState);

export const WebViewProvider = ({ children }: any) => {
  const [visible, setVisible] = useState(false);
  const [url, setUrl] = useState('');
  const openUrl = (url: string) => {

    if (Platform.OS === 'web') {
      window.open(url, '_blank');
    } else {
      setUrl(url);
      setVisible(true);
    }
  }

  return (
    <WebViewContext.Provider value={{openUrl}}>
      {children}
      {
        visible && (
          <WebViewComponent url={url} setVisible={setVisible}/>
        )
      }
    </WebViewContext.Provider>
  );
};
