import {useRef} from 'react';
import { WebView } from 'react-native-webview';
import {View, Text, Image, Platform, Pressable} from "react-native";
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import cultivateLogoBlue from '../../../assets/cultivate-logobluepurple.png'

const WebViewComponent = ({url, setVisible}: any) => {
  const webViewRef = useRef(null)
  
  const goBack = () => {
    if (webViewRef.current){  
      (webViewRef.current as any).goBack();
    }
  };

  const goForward = () => {
    if (webViewRef.current){  
      (webViewRef.current as any).goForward();
    }
  };

  const reload = () => {
    if (webViewRef.current){  
      (webViewRef.current as any).reload();
    }
  };

  return (
      <View style={{height: "100%", width: "100%"}}>
        <View style={{height: 60, backgroundColor: "white", justifyContent: "center", alignItems: "center", borderBottomWidth: 1, borderColor: "grey"}}>
          <View style={{position: "absolute", top: 16, left: 20}}>
            <Pressable onPress={()=>setVisible(false)}>
              <MaterialCommunityIcons name="close-circle-outline" color="#000" size={30} />
            </Pressable>
          </View>
          <View>
            <Image source={{uri: cultivateLogoBlue}} resizeMode='contain' style={{width: 120, height: 30}}></Image>
          </View>
        </View>
        <View style={{flex: 1}}>
          <WebView ref={webViewRef} source={{ uri: url }} style={{ flex: 1 }}/>
        </View>
        <View style={{height: 60, backgroundColor: "white", borderTopWidth: 1, borderColor: "grey", justifyContent: "space-between", flexDirection: "row", alignItems: "center"}}>
          <View style={{flexDirection: "row", marginLeft: 20}}>
            <Pressable onPress={goBack}>
              <MaterialCommunityIcons name="arrow-left" color="#000" size={26} style={{marginRight: 10}}/>
            </Pressable>
            <Pressable onPress={goForward}>
              <MaterialCommunityIcons name="arrow-right" color="#000" size={26} />
            </Pressable>
          </View>
          <View style={{marginRight: 20}}>
          <Pressable onPress={reload}>
            <MaterialCommunityIcons name="refresh" color="#000" size={26} />
          </Pressable>
          </View>
        </View>
      </View>
    )
}


export default WebViewComponent;
