import React, {useContext, useEffect} from "react";
import { BottomBold, BottomText, Container, ContainerWrapper, DescriptionText, FieldContainer, InputContainer, LogoContainer, TextContainer, TitleText } from "./styles/signup";
import { Image, Text, View, Pressable } from "react-native";
import InputField from "../components/InputField";
import LongButton from "../components/LongButton";
import SafeArea from "../components/SafeArea";
import { AuthContext } from "../contexts/AuthContext";
import { LinearGradient } from 'expo-linear-gradient';
import cultivateLogo from '../../assets/cultivate-logo.png'




const SignUp = ({navigation}: any): JSX.Element => {
  const authContext = useContext(AuthContext)


  useEffect(() => {
    if (authContext.isAuthenticated){
      navigation.navigate('App')
    }
  },[authContext.isAuthenticated])

  return (
      <LinearGradient colors={['#0013FF', '#9E00FF']} style={{height: "100%"}}>
          <View style={{height: "100%",width: "100%"}}>
              <View style={{flex: 1, justifyContent: 'center', alignContent: 'center'}}>
                <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                  <View style={{marginTop: 50, width: 140, height: 140, backgroundColor: "#fff", justifyContent: "center", alignItems: "center" , borderRadius: 70}}>
                    <Image source={{uri: cultivateLogo}} resizeMode='contain' style={{width: 100, height: 100}}/>
                  </View>
                  <View style={{
                    justifyContent: "center",
                    marginTop: 50,
                    }}>
                      <Text style={{color: "#fff", textAlign: "center",
                      fontSize: 40,fontFamily: 'Inter-SemiBold'}}>
                        Cultivate
                      </Text>
                      <Text style={{ color: "#fff",fontFamily: 'Inter-Regular', textAlign: 'center'}}>Join us to start earning cash back</Text>
                  </View>
                </View>
                <View style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  marginLeft: 10,
                  marginRight: 10}}>
                  <View  style={{
                    marginTop: 30,
                    width: "100%", 
                  }}>
                    {navigation &&
                      <Pressable onPress={authContext.loginWithAuth0}>
                        <LongButton text="Sign In/Sign up" textColor="#000" backgroundColor="#fff"/>
                      </Pressable>
                    }
                  </View>
                </View>
              </View>
          </View>
      </LinearGradient>
  )
};

export default SignUp;
