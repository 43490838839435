import styled from 'styled-components';
import { View, Image, Text } from 'react-native';


export const TextContainer = styled(View)`
  flex: 1;
  margin-left: 10px;
  justify-content: center;
`;

export const NameText = styled(Text)`
  color: #000;
  margin-top: 5px;
  font-family: 'Inter-Regular';
`;
export const RateText = styled(Text)`
  color: #0013FF;
  margin-top: 5px;
  font-weight: bold;
  font-family: 'Inter-Regular';
`;

export const DetailsText = styled(Text)`
  color: #0013FF;
  font-family: 'Inter-Regular';
`;
