import React, { createContext, useState, useContext, useEffect } from 'react';
import { doRequest, useCultivateBaseApi, useCultivateMobileApi } from '../api/ApiClient';
import { AuthContext } from './AuthContext';
import EncryptedStorage from 'react-native-encrypted-storage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';


export interface NearbyContextType {
  nearbyStores: any[],
  getMerchantsNearby: any,
  getNearbyProducts: any, 
  setZipCode: any,
  currentZipcode: string
}
// Initial State
const initialState = {
  nearbyStores: [],
  getMerchantsNearby: () => null,
  getNearbyProducts: () => null,
  currentZipcode: "",
  setZipCode: () => null,
};

// Create Context
export const NearbyContext = createContext<NearbyContextType>(initialState);

export const NearbyProvider = ({ children }: any) => {
  const authContext = useContext(AuthContext);
  const [nearbyStores, setNearbyStores] = useState(initialState.nearbyStores);
  const [currentZipcode, setCurrentZipcode] = useState('');

  useEffect(()=>{
    getZipCode().then((zipcode) => {
      setCurrentZipcode(zipcode);
    })
  },[])

  const getMerchantsNearby = async (q: string, sw: string, ne: string) => {
    const response = await doRequest({method: 'GET', url: useCultivateBaseApi + useCultivateMobileApi + `/merchants/search/location`,  data: {q: q, sw: sw, ne: ne} })  
    return response.merchants;
  };

  const getNearbyProducts = async (q: string, zipcode: string) => {
    const response = await doRequest({method: 'GET', url: useCultivateBaseApi + useCultivateMobileApi + `/merchants/search/local`,  data: {q: q, zip: zipcode} })
    return response.results;
  };


  const setZipCode = async (zipcode: string) => {
    try {
      setCurrentZipcode(zipcode);
      await AsyncStorage.setItem(
        "zipcode",
        zipcode
      )
      // Congrats! You've just stored your first value!
    } catch (error) {
      console.log("Error setting zipcode",error)
      // There was an error on the native side
    }
  }

  const getZipCode= async () => {
    try {   
        const zipcode = await AsyncStorage.getItem("zipcode")
        if (zipcode !== undefined && zipcode !== null) {
            return zipcode;
        }
        return "";
    } catch (error) {
        console.log("Error getting zipcode",error)
        return "";
    }
  }

  return (
    <NearbyContext.Provider value={{
      setZipCode,
      currentZipcode,
      nearbyStores,
      getMerchantsNearby,
      getNearbyProducts
    }}>
      {children}
    </NearbyContext.Provider>
  );
};
