/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 */

import React, {useContext} from 'react';
import 'react-native-gesture-handler';
import type {PropsWithChildren} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  useColorScheme,
  View,
} from 'react-native';
import RootNavigator from './src/navigation'
import Nearby from './src/screens/nearby';
import { MD2LightTheme,PaperProvider } from 'react-native-paper';
import Search from './src/screens/search';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
const theme = {
  ...MD2LightTheme,
};
import * as WebBrowser from 'expo-web-browser';
import { Platform } from 'react-native';

if (Platform.OS === 'web') {
  WebBrowser.maybeCompleteAuthSession();
}


function App(): JSX.Element {
  const isDarkMode = useColorScheme() === 'dark';
  return (
    <>
    <GestureHandlerRootView style={{ flex: 1 }}>
        <StatusBar
          barStyle={'dark-content'}
        />
        <PaperProvider theme={theme}>
          <RootNavigator />
        </PaperProvider>
      </GestureHandlerRootView>
    </>
  );
}

export default App;
