import React, {useContext, useEffect} from "react";

import { LogoImage, MerchantNameText, MerchantRateText } from "./styles";
import { Pressable, View, Image, Text } from "react-native";
import { useCultivateBaseApi } from "../../api/ApiClient";
import { WebViewContext } from "../../contexts/WebViewContext";
import { AuthContext } from "../../contexts/AuthContext";

const MerchantCard = ({name, rate, logo, activationUrl, id}: any): JSX.Element => {
  const webViewContext = useContext(WebViewContext);
  const authContext = useContext(AuthContext);

  return (
    <Pressable onPress={async ()=>{webViewContext.openUrl(`${useCultivateBaseApi}/activate?url=${encodeURIComponent(activationUrl)}&merchantId=${encodeURIComponent(id)}&idToken=${await authContext.getIdToken()}&source=mobile`)}}>
      <View style={{
        marginRight: 20,
        marginTop: 3,
        marginBottom: 3,
        marginLeft: 3,
        alignItems: 'center',
        shadowColor: 'grey',
        shadowOffset: {width: 2, height: 2},
        shadowOpacity: 0.2,
        shadowRadius: 3,
        padding: 10,
        backgroundColor: "#fff",
        borderRadius: 12,
        height: 120,
        width: 140,
        justifyContent: "space-between"
      }}>
        <View style={{backgroundColor: 'white', borderRadius: 10}}>
          <Image style={{height: 40, width: 80, resizeMode: "contain"}} source={{uri: `https://${logo.domain}/${logo.path}?ixlib=js-2.3.2&w=500`}}/>
        </View>
        <View style={{justifyContent: "space-evenly", alignItems: "center", marginTop: 5}}>
          <Text style={{
            color: "black",
            marginBottom: 5,
            height: 35,
            fontWeight: "normal",
            fontFamily: 'Inter-Regular'
            }} ellipsizeMode="tail" numberOfLines={2}>{name}</Text>
        </View>
        <Text style={{
        color: "#0013FF",
        fontWeight: "bold",
        marginBottom: 5,
        fontFamily: 'Inter-Regular'
        }}
        >{rate}% cash back</Text>
      </View>
    </Pressable>
  );
};

export default MerchantCard;
