import React from "react";

import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { View, Text } from "react-native";
interface LongButtonProps {
  title: string;
  iconName: string;
}


const CategoryButton = ({title, iconName}: LongButtonProps ): JSX.Element => {

  return (
    <View style={{ 
      justifyContent: 'flex-start',
      alignItems: 'center'}}>
      <View style={{
         marginTop: 5,
         height: 65,
         width: 65,
         backgroundColor: "#fff",
         justifyContent: "center",
         alignItems: "center",
         borderRadius: 15,
         shadowColor: 'grey',
         shadowOffset: {width: 2, height: 2},
         shadowOpacity: 0.2,
         shadowRadius: 3,

      }}>
        <MaterialCommunityIcons name={iconName} color={"#0013FF"} size={26} />
      </View>
      <Text style={{
        textAlign: 'center',
        color: "#000",
        marginTop: 5,
        width: 100,
        fontFamily: 'Inter-Regular'
        }}>{title}</Text>
    </View>
  );
};

export default CategoryButton;
