import { SafeAreaView, Platform } from 'react-native';

const SafeArea = (props: any) => {
  if (Platform.OS === "web") {
    return <>{props.children}</>; // For web, returning an empty fragment or you can return null as well
  }
  return <SafeAreaView {...props} />; // For other platforms, returning SafeAreaView
};

export default SafeArea;
