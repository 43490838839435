import React, { createContext, useState, useContext, useEffect } from 'react';
import { doRequest, useCultivateBaseApi, useCultivateMobileApi } from '../api/ApiClient';
import { AuthContext } from './AuthContext';


export interface ProfileContextType {
  leaderboards: any[];
  rewards: any
  getLeaderboards: any
  getRewards: any
}
// Initial State
const initialState = {
  leaderboards: [],
  rewards: {},
  getLeaderboards: () => null,
  getRewards: () => null,
};

// Create Context
export const ProfileContext = createContext<ProfileContextType>(initialState);

export const ProfileProvider = ({ children }: any) => {
  const authContext = useContext(AuthContext)
  const [leaderboards, setLeaderboards] = useState<any[]>(initialState.leaderboards);
  const [rewards, setRewards] = useState<any>(initialState.rewards);

  useEffect(()=>{
    getLeaderboards();
    getRewards();
  },[authContext.credentials])

  const getLeaderboards = async () => {
    try {
      const idToken  = await authContext.getIdToken()
      const response = await doRequest({method: 'GET', url: useCultivateBaseApi + useCultivateMobileApi + '/profile/leaderboard', headers: { 'x-id-token': idToken}})  
      setLeaderboards(response.leaderboard || []);
    } catch (error) {
      console.error("Error fetching leaderboards:", error);
    }
  };

  const getRewards = async () => {
    try {
      const idToken  = await authContext.getIdToken()
      const response = await doRequest({method: 'GET', url: useCultivateBaseApi + useCultivateMobileApi + '/profile/rewards', headers: { 'x-id-token': idToken}})  
      setRewards(response);
    } catch (error) {
      console.error("Error fetching rewards:", error);
    }
  };

  return (
    <ProfileContext.Provider value={{
      leaderboards,
      rewards,
      getLeaderboards,
      getRewards
    }}>
      {children}
    </ProfileContext.Provider>
  );
};
