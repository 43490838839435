import React, { createContext, useState, useContext, useEffect } from 'react';
import { doRequest, useCultivateBaseApi, useCultivateMobileApi } from '../api/ApiClient';
import { AuthContext } from './AuthContext';


export interface TransferContextType {
  transferToCharity: any
  transferToCash: any
}
// Initial State
const initialState = {
  transferToCharity: () => null,
  transferToCash: () => null,
};

// Create Context
export const TransferContext = createContext<TransferContextType>(initialState);

export const TransferProvider = ({ children }: any) => {
  const authContext = useContext(AuthContext)

  const transferToCharity = async () => {
    const idToken  = await authContext.getIdToken();
    const res = await doRequest({method: 'POST', url: useCultivateBaseApi + useCultivateMobileApi + '/transfer/cash-rewards/charity', headers: { 'x-id-token': idToken}})
  };

  const transferToCash = async (name: string, email: string) => {
    const idToken  = await authContext.getIdToken()
    const res = await doRequest({method: 'POST', url: useCultivateBaseApi + useCultivateMobileApi + '/transfer/cash-rewards/claim', headers: { 'x-id-token': idToken}, data: {name, email}})  
  };

  return (
    <TransferContext.Provider value={{
      transferToCharity,
      transferToCash
    }}>
      {children}
    </TransferContext.Provider>
  );
};
