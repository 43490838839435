import React, { createContext, useState, useContext, useEffect } from 'react';
import jwt_decode from "jwt-decode";
import * as AuthSession from "expo-auth-session";
import { Platform } from 'react-native';
import * as WebBrowser from 'expo-web-browser';

export interface AuthContextType {
  credentials: any,
  categories: any,
  loginWithAuth0: any,
  isAuthenticated: boolean,
  logoutWithAuth0: any,
  getIdToken: any,
  checkAndSetCredentials: any,
}
// Initial State
const initialState = {
  nearbyStores: null,
  categories: null,
  loginWithAuth0: () => null,
  isAuthenticated: false,
  logoutWithAuth0: () => null,
  getIdToken: () => "",
  checkAndSetCredentials: () => false,
};

// Create Context
export const AuthContext = createContext<any>(initialState);

const auth0ClientId = "bgLmyCC2hqUny2Z5eKatfZPGzMmG9PIX";

const useProxy = Platform.select({ web: false, default: true });
const redirectUri = AuthSession.makeRedirectUri({ });

export const AuthProvider = ({ children }: any) => {
  const [credentials, setCredentials] = useState<any>(null);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authorizationEndpoint, setAuthorizationEndpoint] = useState("https://login.wecultivate.us/authorize");

  const [request, result, promptAsync] = AuthSession.useAuthRequest(
    {
      redirectUri,
      clientId: auth0ClientId,
      // id_token will return a JWT token
      responseType: "id_token",
      // retrieve the user's profile
      scopes: ["openid", "profile", "email"],
      extraParams: {
        // ideally, this will be a random value
        nonce: "nonce",
      },
    },
    { authorizationEndpoint }
  );

  useEffect(() => {
    if (result) {
      if ((result as any).error) {
        return;
      }
      if (result.type === "success") {
        setCredentials(result.params)
        // Retrieve the JWT token and decode it
        const jwtToken = result.params.id_token;
        const decoded = jwt_decode(jwtToken);
        setUserInfo(decoded)
        setIsAuthenticated(true)
       //
      }
    }
  }, [result]);

  const checkAndSetCredentials = async () => {
    const creds = credentials
    if (creds !== undefined) {
      setCredentials(creds)
      if (!!creds?.idToken) {
        const decoded = jwt_decode(creds.idToken)
        setUserInfo(decoded)
        setIsAuthenticated(true)
        return true;
      }
    }
    return false;
  }

  const getIdToken = async () => {
    const creds = credentials
    return creds?.id_token || "";
  }

  const loginWithAuth0 = async () => {
    await promptAsync();
  };


  const logoutWithAuth0 = async () => {
    try {
      let logoutUrl = `https://login.wecultivate.us/v2/logout?client_id=${auth0ClientId}&returnTo=${redirectUri}`
      await WebBrowser.openAuthSessionAsync(logoutUrl);
      setIsAuthenticated(false)
      setCredentials(null)
      setUserInfo(null)
    } catch (e) {
        console.log(e);
    }
  };

  return (
    <AuthContext.Provider value={{
      loginWithAuth0,
      logoutWithAuth0,
      getIdToken,
      checkAndSetCredentials,
      credentials,
      userInfo,
      isAuthenticated
    }}>
      {children}
    </AuthContext.Provider>
  );
};
