import React, { createContext, useState, useContext, useEffect } from 'react';
import { doRequest, useCultivateBaseApi, useCultivateMobileApi } from '../api/ApiClient';
import { AuthContext } from './AuthContext';


export interface SearchContextType {
  popularMerchants: any[];
  allMerchants: any[];
  searchMerchants: any[];
  updateAllMerchants: any;
  getSearchMerchants: any;
  categories: any[],
}
// Initial State
const initialState = {
  popularMerchants: [],
  allMerchants: [],
  categories: [],
  searchMerchants: [],
  updateAllMerchants: () => null,
  getSearchMerchants: () => null,
};

// Create Context
export const SearchContext = createContext<SearchContextType>(initialState);

export const SearchProvider = ({ children }: any) => {
  const [popularMerchants, setPopularMerchants] = useState<any>(initialState.popularMerchants);
  const [allMerchants, setAllMerchants] = useState<any>(initialState.allMerchants);
  const [allMerchantsPage, setAllMerchantsPage] = useState<any>(0);
  const [allMerchantsTotalPages,setAllMerchantsTotalPages] = useState(0);
  const [categories, setCategories] = useState(initialState.categories);
  const [searchMerchants, setSearchMerchants] = useState<any>(initialState.searchMerchants);
  
  useEffect(()=>{
    getFeaturedMerchants();
    fetchPopularStores();
    updateAllMerchants();
  },[])

  // Fetch data (assuming you have some API methods to do so)
  const fetchPopularStores = async () => {
    try {
      const response = await doRequest({method: 'GET', url: useCultivateBaseApi + useCultivateMobileApi + '/merchants/popular' })  
      setPopularMerchants(response);
    } catch (error) {
      console.error("Error fetching popular stores:", error);
    }
  };

  const updateAllMerchants = async () => {
    try {
      if (allMerchantsTotalPages !== 0 && allMerchantsPage >= allMerchantsTotalPages) {
        return;
      }
      const response = await doRequest({method: 'GET', data: {page: allMerchantsPage}, url: useCultivateBaseApi + useCultivateMobileApi + '/merchants/all' })  
      setAllMerchants((prevData: any[]) => [...prevData, ...response.results]);
      setAllMerchantsPage((prevPage: number) => prevPage + 1);
      setAllMerchantsTotalPages(response.totalPages || 0);
    } catch (error) {
      console.error("Error updating merchant all", error);
    }
  };

  const getFeaturedMerchants = async () => {
    try {
      const response = await doRequest({method: 'GET', url: useCultivateBaseApi + useCultivateMobileApi + '/merchants/featured' })  
      setCategories(response);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const getSearchMerchants = async (q: string) => {
      const response = await doRequest({method: 'GET', url: useCultivateBaseApi + useCultivateMobileApi + `/merchants/search`,  data: {q: q} })  
      return response.merchants;
  };


  return (
    <SearchContext.Provider value={{
      popularMerchants,
      allMerchants,
      categories,
      searchMerchants,
      getSearchMerchants,
      updateAllMerchants
    }}>
      {children}
    </SearchContext.Provider>
  );
};
