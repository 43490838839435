import React from 'react'
import { NavigationContainer } from '@react-navigation/native';

import {useAuth0, Auth0Provider} from 'react-native-auth0';
import SignUp from '../screens/signup';
import { AuthProvider } from '../contexts/AuthContext';
import { createStackNavigator } from '@react-navigation/stack';
import AppNavigator from './AppNavigation';
import WebViewComponent from '../components/WebView';
import { WebViewProvider } from '../contexts/WebViewContext';
import { SearchProvider } from '../contexts/SearchContext';
import Loading from '../screens/loading';

const RootStackNavigator = createStackNavigator();

const RootNavigator = () => {
  return (
        <AuthProvider>
        <WebViewProvider>
          <SearchProvider>
            <NavigationContainer>
              <RootStackNavigator.Navigator screenOptions={{headerShown: false, gestureEnabled: false}}>
                <RootStackNavigator.Screen name="Loading" component={Loading} />
                <RootStackNavigator.Screen name="SignUp" component={SignUp} />
                <RootStackNavigator.Screen name="App" component={AppNavigator}/>
              </RootStackNavigator.Navigator>
            </NavigationContainer>
          </SearchProvider>
        </WebViewProvider>
        </AuthProvider>
  );
};

export default RootNavigator;
