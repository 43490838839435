import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import Search from '../screens/search';
import MerchantList from '../screens/merchantList';


const SearchStackNavigator = createStackNavigator();

const SearchStack = ({route}: any) => {
  return (
    <SearchStackNavigator.Navigator screenOptions={
      {headerShown: false}
    }>
      <SearchStackNavigator.Screen name="Search" component={Search} />
    </SearchStackNavigator.Navigator>
  );
}

export default SearchStack;
