import React, {useEffect,useContext} from "react";

import {DetailsText, TextContainer, NameText, RateText} from "./styles";
import { View, Linking, Image, Pressable} from "react-native";
import { useCultivateBaseApi } from "../../api/ApiClient";
import { AuthContext } from "../../contexts/AuthContext";
import { WebViewContext } from "../../contexts/WebViewContext";

const MerchantRow = ({ name, rate, logo, id, activationUrl }: any): JSX.Element => {
  const authContext = useContext(AuthContext);
  const webViewContext = useContext(WebViewContext);
  const removeDevDomain = (path: string) => {
    if (path.includes('-dev')){
      return path.replace('-dev', '');
    }
    return path;
  }

  return (
    <View style={{borderBottomWidth: 1, borderBottomColor: "grey", flex: 1, alignItems: 'flex-start'}}>
      <Pressable style={{width: "100%"}} onPress={async ()=>{webViewContext.openUrl(`${useCultivateBaseApi}/activate?url=${encodeURIComponent(activationUrl)}&merchantId=${encodeURIComponent(id)}&idToken=${await authContext.getIdToken()}&source=mobile`)}}>
        <View style={{flex: 1,alignItems: 'center', minHeight: 70, display: 'flex',flexDirection: 'row',justifyContent: 'space-between', width: '100%'}}>
            <View style={{flexDirection: "row", flex: 5, justifyContent: "center"}}>
              <View style={{backgroundColor: "white", borderRadius: 10}}>
                <Image style={{height: 50, width: 100, borderRadius: 10}} source={{uri: `https://${removeDevDomain(logo.domain)}/${logo.path}?ixlib=js-2.3.2&w=500`}} resizeMode='contain'/>
              </View>
              <View style={{flex: 1, marginLeft: 10, justifyContent: 'center'}}>
                <NameText>{name}</NameText>
                <RateText>Up to {rate}% cash back</RateText>
              </View>
            </View>
            <View style={{flex: 1, justifyContent: 'flex-end'}}>
              <DetailsText>Activate</DetailsText>
            </View>
        </View>
      </Pressable>
    </View>
  );
};

export default MerchantRow;
