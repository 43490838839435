import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import Nearby from '../screens/nearby';


const NearbyStackNavigator = createStackNavigator();

const NearbyStack = () => {
  return (
    <NearbyStackNavigator.Navigator screenOptions={
      {headerShown: false}
    }>
      <NearbyStackNavigator.Screen name="Nearby" component={Nearby} />
    </NearbyStackNavigator.Navigator>
  );
}

export default NearbyStack;
