import React, {useContext, useEffect, useState} from "react";
import LongButton from "../components/LongButton";
import { SafeArea } from "./styles/utils";
import { ContainerWrapper } from "./styles/profile";
import { FlatList, Image, ScrollView, StyleSheet, Text, View, Dimensions, Pressable } from "react-native";
import { AuthContext } from "../contexts/AuthContext";
import { ProfileContext } from "../contexts/ProfileContext";
import { LinearGradient } from 'expo-linear-gradient';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { Modal } from "react-native";
import cultivateLogoBlue  from '../../assets/cultivate-logobluepurple.png'

const windowHeight = Dimensions.get('window').height;


const Profile = ({navigation}: any): JSX.Element => {
  const authContext = useContext(AuthContext)
  const profileContext = useContext(ProfileContext)
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  const goToTransfer = () => {
    if (profileContext.rewards && profileContext.rewards.available?.present) {
      navigation.navigate('Transfer')
    } else {
      setIsBottomSheetOpen(true)
    }
  }

  useEffect(() => {
    if(!authContext.isAuthenticated){
      navigation.navigate('SignUp')
    }
  },[authContext.isAuthenticated])

  return (
    <>
        <View style={{height: "100%", width: "100%"}}>
          <ScrollView showsVerticalScrollIndicator={false}>
          <View style={{justifyContent: "center", width: "100%", alignItems: "center"}}>
              <Image source={{uri: cultivateLogoBlue}} resizeMode='contain' style={{width: 120, height: 30}}></Image>
          </View>
          <View style={{flex: 1}}>
            <View style={{alignItems: "center", justifyContent: "center", marginLeft: 10, marginTop: 20, borderBottomColor: "grey", borderBottomWidth: StyleSheet.hairlineWidth}}>
              <Text style={{fontSize: 20, color: "#000", marginBottom: 20, fontWeight: "bold", fontFamily: 'Inter-Regular'}}>{authContext.userInfo && authContext.userInfo.email}</Text>
            </View>
            <View style={{marginLeft: 10, marginRight: 10, marginTop: 10, paddingBottom: 10, borderBottomColor: "grey", borderBottomWidth: StyleSheet.hairlineWidth}}>
              <View style={{flexDirection: "row", justifyContent: "space-between", marginBottom: 10}}>
                <Text style={{fontSize: 20, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Rewards</Text>
                <Pressable onPress={()=> goToTransfer()}>
                  <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                    <Text style={{fontSize: 15, color: "#0013FF", fontFamily: 'Inter-Bold'}}>Transfer</Text>
                    <MaterialIcons name="currency-exchange" color="#0013FF" size={26} style={{marginLeft: 10, marginRight: 10}} />
                  </View>
                </Pressable>
              </View>
              <View style={{ justifyContent: "center",}}>
                <View style={{flexDirection: 'row',justifyContent: 'space-between',marginBottom: 20}}>
                  <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Available rewards</Text>
                  <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>{profileContext.rewards && profileContext.rewards.available?.total}</Text>
                </View>
                <View style={{flexDirection: 'row',justifyContent: 'space-between', marginBottom: 20}}>
                  <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Pending rewards</Text>
                  <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>{profileContext.rewards && profileContext.rewards.pending?.total}</Text>
                </View>
                <View style={{flexDirection: 'row',justifyContent: 'space-between'}}>
                  <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Charity Wallet</Text>
                  <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>{profileContext.rewards && profileContext.rewards.wallet?.total || "$0.00"}</Text>
                </View>
              </View>
            </View>
            <View style={{flex: 3, marginLeft: 10, marginRight: 10}}>
              <Text style={{fontSize: 20, color: "#000", marginBottom: 10, fontWeight: "bold", marginTop: 20, fontFamily: 'Inter-Regular'}}>Leaderboards</Text>
                {profileContext.leaderboards.map((item, index)=>
                  <View key={index} style={{
                  marginBottom: 10,
                  borderColor: "grey",
                  borderWidth: 1, 
                  borderRadius: 10,
                  padding: 10,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: "center",
                  }}>
                    <View>
                      <Text style={{color: "#000", fontWeight: index === profileContext.leaderboards.length - 1 ? "bold": "normal", marginBottom: 1, fontFamily: 'Inter-Regular'}}>{item.redactedUsername}</Text>
                      <Text style={{color: "grey", fontWeight: "normal", fontFamily: 'Inter-Regular'}}>Total rewards: {item.rewardAmount}</Text>
                    </View>
                    <LinearGradient colors={["#0013FF", "#9E00FF"]} style={{height: 20, width: 20, borderRadius: 20, marginRight: 10}}>
                    </LinearGradient>
                  </View>
                )}
            </View>
            <View style={{marginLeft: 10,flexDirection: "row", justifyContent: 'center', marginRight: 10, paddingBottom: 30, marginTop: 10, alignItems: "center"}}>
              <View>
                <Pressable onPress={authContext.logoutWithAuth0}>
                  <Text style={{color: '#0013FF', fontSize: 16, fontWeight: 'bold', fontFamily: 'Inter-Regular'}}>
                    Logout
                  </Text>
                </Pressable>
              </View>
          </View>
          </View>
        </ScrollView>
        </View>
        <Modal
          animationType="slide"
          transparent={true}
          onRequestClose={() => setIsBottomSheetOpen(false)}
          // We use the state here to toggle visibility of Bottom Sheet 
          visible={isBottomSheetOpen}
          >
          <Pressable style={{flex: 1}} onPress={()=>setIsBottomSheetOpen(false)}>
            <Pressable style={{
              position: 'absolute',
              left: 0,
              right: 0,
              justifyContent: 'flex-start',
              alignItems: 'center',
              backgroundColor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              paddingVertical: 23,
              paddingHorizontal: 25,
              bottom: 0,
              borderWidth: 1,
              borderColor: '#0013FF',
              height: windowHeight * 0.4,
            }}
            onPress={()=>setIsBottomSheetOpen(true)}
            >
              <View style={{flex: 1,
                alignItems: 'center',
                width: "100%"
              }}>
                <View style={{borderBottomColor: "grey", borderBottomWidth: StyleSheet.hairlineWidth,  width: "100%", alignItems: "center"}}>
                  <Text style={{fontSize: 20, color: "#000", marginBottom: 10, fontWeight: "bold", marginTop: 10, fontFamily: 'Inter-Regular'}}>You're so close!</Text>
                </View>
                <Text style={{fontSize: 15, color: "grey", marginBottom: 10, fontWeight: "bold", marginTop: 20, fontFamily: 'Inter-Regular', textAlign: "center"}}>You need at least $5 in available rewards in order to cash out or donate</Text>
                <View style={{width: "100%", marginTop: 20}}>
                  <Pressable onPress={()=>setIsBottomSheetOpen(false)}>
                    <View style={{height: 50, borderWidth: 1, borderColor: "#0013FF", borderRadius: 15, justifyContent: "center", alignItems: "center", width: "100%"}}>
                      <Text style={{fontSize: 15, color: "#000", fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Close</Text>
                    </View>
                  </Pressable>
                </View>
              </View>
            </Pressable>
          </Pressable>
        </Modal>
      </>
  );
};

export default Profile;
