import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import Profile from '../screens/profile';


const ProfileStackNavigator = createStackNavigator();

const ProfileStack = ({route}: any) => {
  return (
    <ProfileStackNavigator.Navigator screenOptions={
      {headerShown: false}
    }>
      <ProfileStackNavigator.Screen name="Profile" component={Profile} />
    </ProfileStackNavigator.Navigator>
  );
}

export default ProfileStack;
