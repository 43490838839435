import React, { useContext, useEffect, useState, useMemo,useCallback, useRef } from "react";
import LongButton from "../components/LongButton";
import { FlatList, Image, ScrollView, StyleSheet, Text, View, TextInput, SafeAreaView, Keyboard, Pressable } from "react-native";
import MerchantRow from "../components/MerchantRow";
import MerchantCard from "../components/MerchantCard";
import { SearchContext } from "../contexts/SearchContext";
import { FlashList } from "@shopify/flash-list";
import CategoryButton from "../components/CategoryButton";
import { LinearGradient } from 'expo-linear-gradient';
import SafeArea from "../components/SafeArea";
import cultivateLogoBlue from '../../assets/cultivate-logobluepurple.png'


const Search = ({navigation}: any): JSX.Element => {
  const searchContext = useContext(SearchContext);
  const [displayData, setDisplayData] = useState<any[]>([])
  const [value, setValue] = useState('')
  const searchInputRef = useRef(null);

  const[isKeyFocused, setIsKeyFocused] = useState(false)

  useEffect(() => {
    const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
      setIsKeyFocused(false);
    });

    return () => {
      hideSubscription.remove();
    };
  }, []);

  const onSearchSubmit = async () => {
    setValue('')
    setIsKeyFocused(false);
    navigation.navigate('MerchantList', {type: "Search", search: value})
  }

  useEffect(()=>{
    setDisplayData(searchContext.allMerchants.slice(0,50))
  },[searchContext.allMerchants])

 
  const renderItem = useCallback(({ item }: any) => ( <MerchantRow name={item.name || "Unknown"} rate={item.rate} logo={item.logo} id={item.id} activationUrl={item.defaultActivationUrl}/>), [])
  return (
          <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps="always">
            <View style={{justifyContent: "center", width: "100%", alignItems: "center"}}>
              <Image source={{uri: cultivateLogoBlue}} resizeMode='contain' style={{width: 120, height: 30}}></Image>
            </View>
              <View style={{ marginLeft: 10, justifyContent: "center", marginTop: 10, marginRight: 10}}>
                <View>
                  <View style={{width: '100%', flexDirection: "row"}}>
                    <Pressable onPress={()=>{searchInputRef.current && (searchInputRef.current as any).focus()}} style={{height: 50, flex: 5, backgroundColor: '#ededed', paddingLeft: 20, borderRadius: 10, flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingRight: 10}}>
                      <TextInput
                        ref={searchInputRef}
                        style={{color: '#000', flex: 1}}
                        placeholder={"Search merchants by name or products"}
                        placeholderTextColor={'#000'}
                        onChangeText={setValue}
                        value={value}
                        onFocus={()=>setIsKeyFocused(true)}
                        returnKeyType='search'
                        onSubmitEditing={onSearchSubmit}
                      />
                    </Pressable>
                    {isKeyFocused &&
                      <View style={{flexDirection: "row", alignItems: "center", backgroundColor: "#f8f8f8", height: "100%", flex: 1, marginLeft: 5}}>
                        <Pressable onPress={()=>{Keyboard.dismiss(); setIsKeyFocused(false); setValue("")}}>
                            <Text style={{fontSize: 16, color: "#000"}}>
                              Cancel
                            </Text>
                        </Pressable>
                      </View>
                    }
                  </View>
                </View>
              </View>
              <View style={{marginTop: 10, marginLeft: 10 , justifyContent: "center"}}>
                  <Text style={{color: '#000', fontSize: 15, fontWeight: 'bold', marginBottom: 10, fontFamily: 'Inter-Regular'}}>
                    Categories
                  </Text>
                  <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                    {searchContext.categories.map((value, index)=>
                        <Pressable key={index} onPress={()=>navigation.navigate('MerchantList', {type: "Category", search: value.vertical})}>
                          <CategoryButton title={value.vertical} iconName={value.iconName}/>
                        </Pressable>
                      )
                    }
                  </ScrollView>
                </View>
              <View style={{ marginLeft: 10, marginTop: 10, justifyContent: "center"}}>
                <Text style={{fontSize: 15, color: "#000", marginBottom: 10, fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Popular</Text>
                <ScrollView horizontal={true} style={{marginTop: 5, marginBottom: 5}} showsHorizontalScrollIndicator={false}>
                  {
                    searchContext.popularMerchants.map((item, index) => <MerchantCard name={item.name} rate={item.rate} logo={item.logo} id={item.id} activationUrl={item.defaultActivationUrl} key={item.id} />)
                  }
                </ScrollView>
              </View>
              <View style={{ marginLeft: 10, marginTop: 10, marginRight: 10}}>
                <View style={{flexDirection: "row", justifyContent: "space-between", borderBottomColor: "grey", borderBottomWidth: 1}}>
                  <Text style={{fontSize: 15, color: "#000", marginBottom: 10, fontWeight: "bold", fontFamily: 'Inter-Regular'}}>Merchants</Text>
                  <Pressable onPress={()=>navigation.navigate('MerchantList', {type: "All", search: "All Merchants"})}>
                    <Text style={{fontSize: 15, color: "#0013FF", marginBottom: 10, fontWeight: "bold", fontFamily: 'Inter-Regular'}}>See All</Text>
                  </Pressable>
                </View>
                <FlashList 
                    estimatedItemSize={50}
                    keyExtractor={(item, index) => item.id.toString()}
                    data={displayData} renderItem={renderItem} showsVerticalScrollIndicator={true} />
              </View>
          </ScrollView>
  );
};

export default Search;
