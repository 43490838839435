import React, {useEffect, useContext, useCallback, useState} from "react";
import LongButton from "../components/LongButton";
import { ButtonContainer, Container, ContainerWrapper, Description, HeroContainer, HeroImage, TextContent, Title } from "./styles/join";
import { View, ScrollView, Text, ActivityIndicator, SafeAreaView, StyleSheet} from "react-native";
import { SafeArea } from "./styles/utils";
import { NearbyContext } from "../contexts/NearbyContext";
import MerchantRow from "../components/MerchantRow";
import { FlashList } from "@shopify/flash-list";
import { SearchContext } from "../contexts/SearchContext";
import { LinearGradient } from 'expo-linear-gradient';


const MerchantList = ({route}: any): JSX.Element => {
  const searchContext = useContext(SearchContext)
  const [displayData, setDisplayData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  
  const getSearchMerchants = async () => {
    setLoading(true)
    try{
      const merchants = await searchContext.getSearchMerchants(route.params.search)
      setDisplayData(merchants)
      setLoading(false)
    } catch(err){
      setLoading(false)
    }
  }

  useEffect(()=>{
    if(route.params.type === "Category"){
      setDisplayData(searchContext.categories.find((merchant)=>merchant.vertical === route.params.search).merchants)
    }else if (route.params.type === "Search"){
      getSearchMerchants()
    } else if (route.params.type === "All"){
      setDisplayData(searchContext.allMerchants)
    }
  },[route])

  useEffect(()=>{
    if(route.params.type === "All"){
      setDisplayData(searchContext.allMerchants)
    }
  },[searchContext.allMerchants])

  const renderItem = useCallback(({ item }: any) => ( <MerchantRow name={item.name || "Unknown"} rate={item.rate} logo={item.logo} id={item.id} key={item.id} activationUrl={item.defaultActivationUrl}/>), [])
  return (
      <ContainerWrapper style={{ borderBottomColor: "grey", borderBottomWidth: 1}}>
          <View style={{flex: 1, marginLeft: 10, marginRight: 10}}>
            {!loading ?
              <FlashList
                estimatedItemSize={50}
                data={displayData}
                renderItem={renderItem}
                showsVerticalScrollIndicator={false}
                onEndReachedThreshold={0.5}
                onEndReached={()=>route.params.type === "All" ? searchContext.updateAllMerchants(): null}
              />
              :
              <View style={{marginTop: 20}}>
                <ActivityIndicator/>
              </View>
            }
          </View>
      </ContainerWrapper>
  );
};

export default MerchantList;
