
import Config from "react-native-config";
const requestTimeout = 1000 * 15 // 15 seconds

const baseUrl = Config.APP_BASE_URL || process.env.API_URL || 'https://www.wecultivate.us'

export const useCultivateBaseApi = baseUrl
export const useCultivateMobileApi = '/api/mobile/v1'

export function doRequest({ method = 'GET', data, url, headers, fetchOptions }: any): Promise<any> {

  method = method.toUpperCase();

  return new Promise((resolve, reject) => {
    const abortController = new AbortController();

    const abortTimerId = setTimeout(() => {
      if (abortController) {
        abortController.abort();
      }
    }, requestTimeout);

    if (data && method === 'GET') {
      url += '?' + Object.entries(data)
        .filter(([, v]) => v != null)
        .map(([k, v]) => `${k}=${encodeURIComponent(v as string)}`)
        .join('&');
    }

    fetch(url, {
      method,
      ...(data && method !== 'GET' && { body: JSON.stringify(data) }),
      headers: {
        ...(method !== 'GET' && {
          'Content-Type': 'application/json'
        }),
        ...headers
      },
      signal: abortController.signal,
      ...fetchOptions
    })
      .then((resp) => {
        clearTimeout(abortTimerId);
        
        if (resp.status >= 200 && resp.status < 300) {
          return resp.json()
            .then(responseJSON => {
              resolve(responseJSON);
            });
        } else {
          reject(new Error(`HTTP status ${resp.status}`));
        }
      })
      .catch(e => {
        clearTimeout(abortTimerId);
        reject(e);
      });
  });
}
