import React, {useEffect,useContext} from "react";

import {LogoImage, MainContainer} from "./styles";
import { View, Linking, Text, Pressable, Image} from "react-native";
import { useCultivateBaseApi } from "../../api/ApiClient";
import { AuthContext } from "../../contexts/AuthContext";
import { WebViewContext } from "../../contexts/WebViewContext";
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

const ProductRow = ({ name, price, image, id, url, merchantName, distance, savingsAmount, merchantId, rating, reviewCount }: any): JSX.Element => {
  const authContext = useContext(AuthContext);
  const webViewContext = useContext(WebViewContext);
  const parseName = (name: string) => {
    if(name.length > 50) {
      return name.slice(0,50) + "...";
    }
    return name;
  }
  
  const ratingToStars = (rating: string) => {
    if(!rating) {
      return [];
    }
    let parsedRating = parseFloat(rating)
    const maxStars = 5;
    const stars = [];

    for (let i = 0; i < Math.floor(parsedRating); i++) {
        stars.push(<MaterialCommunityIcons name="star" color="#f5d142" size={20} />);
    }

    if (parsedRating % 1 !== 0) {
        stars.push(<MaterialCommunityIcons name="star-half-full" color="#f5d142" size={20} />);
    }

    while (stars.length < maxStars) {
        stars.push(<MaterialCommunityIcons name="star-outline" color="#f5d142" size={20} />);
    }

    return stars;
  }

  const productUrl = async () => {
    if (!!merchantId){
      return `activate?url=${encodeURIComponent(url)}&merchantId=${encodeURIComponent(merchantId)}&idToken=${await authContext.getIdToken()}&source=mobile`
    } else {
      return `np?u=${encodeURIComponent(url)}`
    }
  }


  return (
    <Pressable onPress={async ()=>{webViewContext.openUrl(`${useCultivateBaseApi}/${await productUrl()}`)}}>
      <View style={{
        flex: 1,
        width: "100%",
        alignItems: "flex-start",
        flexDirection: "row",
        justifyContent: "space-between",
        height: 130,
        borderBottomWidth: 0.5,
        borderBottomColor: "grey"}}>
          <View style={{flex: 1, justifyContent: "center", height: "100%"}}>
            <Image style={{
              width: "100%",
              height: 80,
              borderRadius: 10
              }} source={{uri: image}} resizeMode='contain'/>
          </View>
          <View style={{flex: 2, justifyContent: "space-between", height: "100%"}}>
            <View style={{marginTop: 5}}>
              <Text style={{color: "#000", fontWeight: "bold", fontFamily: "Inter-Regular"}}>{parseName(name)}</Text>
              <Text style={{color: "#000", marginTop: 5, fontFamily: "Inter-Regular"}}>{merchantName}</Text>
            </View>
            <View style={{marginBottom: 5}}>
              <View style={{flexDirection: "row", alignItems: "center", marginBottom: 5}}>
                <View style={{flexDirection: "row"}}>
                  {ratingToStars(rating).map((item, index)=>item)}
                </View>
                {reviewCount &&
                  <Text style={{color: "grey", marginLeft: 5, fontFamily: "Inter-Regular"}}>({reviewCount}) ratings</Text>
                }
              </View>
              <View style={{flexDirection: "row", marginBottom: 2}}>
                <Text style={{color: "#000", fontWeight: "bold", marginRight: 5, fontFamily: "Inter-Regular"}}>${price}</Text>
                <Text style={{color: "#000", marginRight: 5, fontFamily: "Inter-Regular"}}>({distance})</Text>
              </View>
              <View style={{flexDirection: "row"}}>
                { Number(savingsAmount) > 0 && 
                  <Text style={{color: "#0013FF", marginRight: 5, fontFamily: "Inter-Regular"}}>Get ${savingsAmount} in cash back</Text>
                }
              </View>
            </View>
          </View>
      </View>
    </Pressable>
  );
};

export default ProductRow;
