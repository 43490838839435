import React from 'react'
import { NavigationContainer } from '@react-navigation/native';

import BottomTabs from './Tabs';
import { ProfileProvider } from '../contexts/ProfileContext';
import { NearbyProvider } from '../contexts/NearbyContext';
import {useAuth0, Auth0Provider} from 'react-native-auth0';
import { createStackNavigator } from '@react-navigation/stack';
import MerchantList from '../screens/merchantList';
import Transfer from '../screens/transfer';
import { TransferProvider } from '../contexts/TransferContext';
const AppNavigatorStack = createStackNavigator();

const AppNavigator = () => {
  return (
      <NearbyProvider>
          <ProfileProvider>
          <TransferProvider>
            <AppNavigatorStack.Navigator>
                <AppNavigatorStack.Screen name="Tabs" component={BottomTabs} options={{headerShown: false}} />
                <AppNavigatorStack.Screen name="MerchantList" component={MerchantList} options={ ({route}: any) => (
                  {
                    title: route.params.search || "Merchant List",
                    headerTitleStyle: {
                      color: '#000'
                    },
                    headerBackTitle: 'Back',
                    headerLeftContainerStyle: {
                      paddingLeft: 20,
                    },
                    headerShown: true,
                    headerBackTitleStyle: {
                      color: '#000'
                    },
                    headerStyle: {
                      backgroundColor: '#f8f8f8',
                      borderBottomWidth: 1,
                      borderBottomColor: 'grey'
                    }
                  })
                }
                />
                <AppNavigatorStack.Screen name="Transfer" component={Transfer} options={ ({route}: any) => (
                  {
                    title: "Transfer",
                    headerBackImage: () => null,
                    headerTitleStyle: {
                      color: '#000'
                    },
                    headerBackTitle: 'Back',
                    headerLeftContainerStyle: {
                      paddingLeft: 20,
                    },
                    headerShown: true,
                    headerBackTitleStyle: {
                      color: '#000'
                    },
                    headerStyle: {
                      backgroundColor: '#f8f8f8',
                      borderBottomWidth: 1,
                      borderBottomColor: 'grey'
                    }
                  }
                )}
              />
              </AppNavigatorStack.Navigator>
            </TransferProvider>
          </ProfileProvider>
      </NearbyProvider>
  );
};

export default AppNavigator;
